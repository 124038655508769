/*
 * @Author: 露寒 _(:зゝ∠)_
 * @Date: 2021-10-12 
 * @LastEditTime: 2023-04-20 13:44:11
 * @LastEditors: 18782733838 1925176884@qq.com
 * @Description: 便于页面管理，二次封装axios接口
 */
// 对原生的 axios 做进一步的封装
import axios from 'axios';
import router from '../router'
import errorCode from '@/utils/errorCode'
import { Toast } from 'vant'



// const BASEURL = 'http://192.168.0.200:8080'; //雪峰
// const BASEURL = '/api'; //雪峰
// const BASEURL = 'http://192.168.0.37:8010'; //吉伦
// const BASEURL='http://59.36.137.22:8010'//测试
// const BASEURL='https://gx.chengdutalent.cn:8010';//正式服务器


const instance = axios.create({
baseURL: 'https://mihuatang.xyz/user',
 //  baseURL:'http://192.168.0.83:8091' ,
    timeout: 10000,// 设置超时的时间，超过设置时间自动断开连接
    
});

// 设置请求拦截器：给所有的 axios 请求设置统一的请求头（添加 token）
instance.interceptors.request.use( //数据返回到发送请求的地方
    config => {
        // 如果请求拦截成功
        // 1. 获取本地存储中的 token
        const token =JSON.parse( sessionStorage.getItem('token'))
       if(token){
        console.log(token.token);
        // 2. 将 token 添加到请求头中
       config.headers.Authorization = token.token;
       }
       
        return config;
 },
    err => {
        // 如果请求拦截失败
        // return err;
        return Promise.reject(err);
    }
);
    // //axios后置拦截器
    // instance.interceptors.response.use(result => {
    //     let {success, msg} = result.data;
    //     if(!success && msg === "noLogin"){
    //         //清空本地存储
    //         localStorage.removeItem("token");
    //         localStorage.removeItem("loginUser");
    //         router.push({ path: '/login' });
    //     }
    //     return result;
    // }, error => {
    //     Promise.reject(error);
    // });

// 响应拦截器
instance.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.message || res.data.msg  || errorCode['default']
    if (code === 777777) {        
        console.log('未登录或者登录已经过期');
        Toast('未登录或者登录已经过期');
        localStorage.clear()
        sessionStorage.clear()
        router.push({ path: '/' });
        // router.replace('/')
        return res
    } else if (code === -200) {
        console.log(msg);
       Toast(msg);
        return res.data
        // return Promise.reject(new Error(msg,code))
        
    }
    // else if (code === 777777) {
    //     console.log(msg);
    //     Toast(msg);
    //     localStorage.clear()
    //     this.$router.push({
    //         path:'/'
    //     })
    //     return res.data
    //     // return Promise.reject(new Error(msg,code))
        
    // }
    else if (code === 500) {
        console.log(msg);
        Toast(msg);
        return Promise.reject(new Error(msg))
    } else if (code !== 200) {
        console.log(msg);
        Toast(msg);
        return Promise.reject('error')
    } else {
		
        return res.data
    }
},
    error => {
        let { message } = error;
        if (message == "Network Error") {
            message = "后端接口连接异常";
        } else if (message.includes("timeout")) {
            message = "系统接口请求超时";
        } else if (message.includes("Request failed with status code")) {
            message = "系统接口" + message.substr(message.length - 3) + "异常";
        }
        
        console.log(message);
        return Promise.reject(error)
    }
)
export default instance;