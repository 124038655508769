<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  name: 'Home',
  components: {
   
  },
  data() {
    return {
    
    }
  },
  created(){
    if (typeof WeixinJSBridge == "object" && typeof WeixinJSBridge.invoke == "function") {
    handleFontSize();
   } else {
    document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
   }

   function handleFontSize() {
    // 设置网页字体为默认大小
    WeixinJSBridge.invoke('setFontSizeCallback', {
     'fontSize': 0
    });
    // 重写设置网页字体大小的事件
    WeixinJSBridge.on('menu:setfont', function() {
     WeixinJSBridge.invoke('setFontSizeCallback', {
      'fontSize': 0
     });
    });
   }
  },
}
</script>
<style lang="scss">
.app{
   width: 100%;
    height: 100%;
}
  body{
    margin: 0;
  }
</style>
