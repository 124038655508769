// page:身份验证类接口
// time:2021.8.19

import axios from '../axios'
// import qs from 'qs'
// 
// 获取验证码
export const code_api = (data) => {
    return axios({
        method: "post", // 请求方式
        url: "/code", // 请求地址
        data: data,
    });
};
// 注册
export const regis_api = (data) => {
    return axios({
        method: "post", // 请求方式
        url: "/registered", // 请求地址
        data: data,
    });
};
// 登录
export const login_api = (data) => {
    return axios({
        method: "post", // 请求方式
        url: "/login", // 请求地址
        data: data,
    });
};
// 修改手机号
export const newphone_api = (data) => {
    return axios({
        method: "post", // 请求方式
        url: "/updatePhone", // 请求地址
        data: data,
    });
};
// 修改资料
export const newdeta_api = (data) => {
    return axios({
        method: "post", // 请求方式
        url: "/updateUser", // 请求地址
        data: data,
    });
};
//查寻用户信息
export const newinfo_api = (data) => {
    return axios({
        method: "get", // 请求方式
        url: "/getUserIntegral", // 请求地址
        params: data,
    });
};
//忘记密码
export const forpass_api = (data) => {
    return axios({
        method: "post", // 请求方式
        url: "/forgetPassword", // 请求地址
        data: data,
    });
};
export const codeopen_api = (data) => {
    return axios({
        method: "get", // 请求方式
        url: "/getOpenid", // 请求地址
        params: data,
    });
};