// page:身份验证类接口
// time:2021.8.19

import axios from '../axios'
// 
// 获取用户优惠券
export const discount_api = (data) => {
    return axios({
        method: "get", // 请求方式
        url: "/coupon/getUserCoupon", // 请求地址
        params: data,
    });
};
// 评价订单详情
export const xxdeta_api = (data) => {
    return axios({
        method: "get", // 请求方式
        url: "/evaluate/details", // 请求地址
        params: data,
    });
};
// 使用优惠券
export const useco_api = (data) => {
    return axios({
        method: "post", // 请求方式
        url: "/coupon/useCoupon", // 请求地址
        data: data,
    });
};
// 订单评价
export const stars_api = (data) => {
    return axios({
        method: "get", // 请求方式
        url: "/evaluate/add", // 请求地址
        params: data,
    });
};
// 评价详情
export const pjdetails_api = (data) => {
    return axios({
        method: "get", // 请求方式
        url: "/evaluate/details", // 请求地址
        params: data,
    });
};
// 订单投诉
export const complain_api = (data) => {
    return axios({
        method: "get", // 请求方式
        url: "/complain/add", // 请求地址
        params: data,
    });
};
// 订单投诉详情
export const complaindeta_api = (data) => {
    return axios({
        method: "get", // 请求方式
        url: "/complain/detailed", // 请求地址
        params: data,
    });
};
// 订单投诉原因
export const getComplaint_api = (data) => {
    return axios({
        method: "get", // 请求方式
        url: "/complain/getComplaint", // 请求地址
        params: data,
    });
};
// code获取优惠券
export const code_api = (data) => {
    return axios({
        method: "post", // 请求方式
        url: "/coupon/useCouponCode", // 请求地址
        data: data,
    });
};
// 查询可以开票的记录
export const getuser_api = (data) => {
    return axios({
        method: "get", // 请求方式
        url: "/bill/getUserBillOrder", // 请求地址
        params: data,
    });
};
// 添加发票
export const addbill_api = (data) => {
    return axios({
        method: "post", // 请求方式
        url: "/bill/addBill", // 请求地址
        data: data,
    });
};
// 添加发票
export const getbill_api = (data) => {
    return axios({
        method: "get", // 请求方式
        url: "/bill/getUserBill", // 请求地址
        params: data,
    });
};