import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './http/api/api.js';
import rem from './utils/rem.js';
import 'lib-flexible'
// import Vconsole from 'vconsole';
// new Vconsole();
// 如果重置样式
import '@/assets/css/resset.css';
//  import 'lib-flexible'//移动端适配
Vue.config.productionTip = false
Vue.prototype.$api = api;
// import VueAMap from 'vue-amap'

import Vant from 'vant';
import 'vant/lib/index.css';
import axios from 'axios'
Vue.prototype.$http = axios
Vue.use(Vant);
Vue.use(rem);
// Vue.use(VueAMap)

// VueAMap.initAMapApiLoader({
//     key: 'd1a157dc3b050d0b6aeea42d3d9d9e54',
//     plugin: [ //高德地图扩展插件(按需添加)
//         'AMap.Autocomplete', // 输入提示插件
//         'AMap.PlaceSearch', // POI搜索插件
//         'AMap.Scale', // 右下角缩略图插件 比例尺
//         'AMap.OverView', // 地图鹰眼插件
//         'AMap.ToolBar', // 地图工具条
//         'AMap.MapType', // 类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
//         'AMap.PolyEditor', // 编辑 折线多，边形
//         'AMap.CircleEditor', // 圆形编辑器插件
//         'AMap.Geolocation' // 定位控件，用来获取和展示用户主机所在的经纬度位置
//     ],
//     uiVersion: '1.1', // ui库版本，不配置不加载,
//     v: '2.0'
// })
console.log=()=>{};
import VueAMap from 'vue-amap';
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: 'your amap key',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4'
});
router.beforeEach((to, from, next) => {
  const token =JSON.parse( sessionStorage.getItem('token'))
  console.log(token);
  // 没有token
  if(to.path=='/regis'||to.path=='/rigister'||to.path=='/Agreement'||to.path=='/tcar'){
    next();
  }else if ((to.meta && to.meta.white)) {
    next();
  }
  else if (to.path=='/') {
    next();
  } else if(token){
    next();
  }else{
    router.push('/')
  }
  
});
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')