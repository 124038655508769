import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
//import Login from '../views/Login.vue'


Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/home',
    name: 'Home',
   // component: Home,
    component: () => import("../views/home/Home.vue"),
  },
  {
    path: '/home2',
    name: 'Home2',
   // component: Home,
    component: () => import("../views/home/Home2.vue"),
  },
  //预约机票

  {
    path: '/Ticket',
    name: 'Ticket',
    meta: {
      white: true, // 白名单
    },
    component: () => import("../views/Ticket.vue"),
  },
  {
    path: '/Fjorder',
    name: 'Fjorder',
    meta: {
      white: true, // 白名单
    },
    component: () => import("../views/Fjorder.vue"),
  },
  {
    path: '/Success',
    name: 'Success',
    meta: {
      white: true, // 白名单
    },
    component: () => import("../views/Success.vue"),
  },
  //打车详情
  {
    path: '/address',
    name: 'Address',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/home/Address.vue')
  },
  //地图选址
  {
    path: '/map',
    name: 'Map',
    
    component: () => import(/* webpackChunkName: "about" */ '../views/home/Map.vue')
  },
  //弹出框
  {
    path: '/Popup',
    name: 'Popup',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/Popup.vue')
  },
  //预估价格
  {
    path: '/price',
    name: 'Price',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/Price.vue')
  },
   //接驾
  {
    path: '/pick',
    name: 'Pick',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/Pick.vue')
  },
  //取消
  {
    path: '/cancel',
    name: 'Cancel',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/Cancel.vue')
  },
   //订单已结束
   {
    path: '/over',
    name: 'Over',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/Over.vue')
  },
   //订单支付
   {
    path: '/payment',
    name: 'Payment',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/Payment.vue')
  },
    {
        path: '/paytest',
        name: 'paytest',
        component: () => import(/* webpackChunkName: "about" */ '../views/home/paytest.vue')
  },
  {
    path: '/select',
    name: 'select',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/select.vue')
},
  //打车
  {
    path: '/car',
    name: 'Car',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/home/Car.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  
    {
      path: '/',
      name: 'Login',
      component: Login,
      //component: () => import("../views/home/Home.vue"),
    },
  
  {
    path: '/regis',
    name: 'Regis',
    
    component: () => import('../views/Regis.vue')
  },
  //首页
  {
    path: '/index',
    name: 'Index',
    
    component: () => import('../views/Index.vue')
  },
   //个人中心
   {
    path: '/core',
    name: 'Core',
    
    component: () => import('../views/Core.vue')
  },
  //修改个人中心
  {
    path: '/modify',
    name: 'Modify',
    
    component: () => import('../views/Modify.vue')
  },
  //订单记录
  {
    path: '/order',
    name: 'Order',
    
    component: () => import('../views/Order.vue')
  },
  //订单记录
  {
    path: '/order_det',
    name: 'Order_details',
    
    component: () => import('../views/Order_details.vue')
  },
   //评价订单记录
   {
    path: '/evaluate',
    name: 'Evaluate',
    
    component: () => import('../views/Evaluate.vue')
  },
   //投诉订单记录
   {
    path: '/complaint',
    name: 'Complaint',
    
    component: () => import('../views/Complaint.vue')
  },
   //优惠
   {
    path: '/discount',
    name: 'Discount',
    
    component: () => import('../views/Discount.vue')
  },
  //卡券
  {
    path: '/card',
    name: 'Card',
    
    component: () => import('../views/Card.vue')
  },
  //卡券详情
  {
    path: '/card_deta',
    name: 'Card_details',
    
    component: () => import('../views/Card_details.vue')
  },
  //充值详情
  {
    path: '/Recharge',
    name: 'Recharge',
    
    component: () => import('../views/Recharge.vue')
  },
 //充值
 {
  path: '/Rech',
  name: 'Rech',
  
  component: () => import('../views/Rech.vue')
},
 //充值记录
 {
  path: '/Rechorder',
  name: 'Rechorder',
  
  component: () => import('../views/Rechorder.vue')
},

//充值成功
{
  path: '/Ok_rech',
  name: 'Ok_rech',
  
  component: () => import('../views/Ok_rech.vue')
},
//修改手机号
{
  path: '/phon',
  name: 'Phon',
  
  component: () => import('../views/Phon.vue')
},
{
  path: '/rigister',
  name: 'Rigister',
  
  component: () => import('../views/Rigister.vue')
},
{
  path: '/Agreement',
  name: 'Agreement',
  
  component: () => import('../views/Agreement.vue')
},
//发票
{
  path: '/Invoice',
  name: 'Invoice',
  
  component: () => import('../views/Invoice.vue')
},
//开发票
{
  path: '/Draw',
  name: 'Draw',
  
  component: () => import('../views/Draw.vue')
},
//开发票成功
{
  path: '/Okbuill',
  name: 'Okbuill',
  
  component: () => import('../views/Okbuill.vue')
},
//开发票详情
{
  path: '/details',
  name: 'Details',
  
  component: () => import('../views/Details.vue')
  },
//停车
{
  path: '/tcar',
  name: 'Tcar',
  
  component: () => import('../views/Tcar.vue')
},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
