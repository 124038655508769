// page:申报类接口
// time:2021.8.18

import axios from '../axios'
const declare = {
    
    //统一社会信用码抓取 √
    licenseCatch(data) {
        return axios({
            url: "/user/sys/file/license",
            method: "post",
            data
        })
    },

    // 获取对应类型下面的文本 √
    getAllType(params) {
        return axios({
            url: "/user/declare/materials",
            method: "get",
            params
        })
    },
    //所需申报材料字典 √
    getMaterial(params) {
        return axios({
            url: "/user/declare/loadModulePath",
            method: "get",
            params
        })
    },
    //前台上传图片 √
    uploadImg(data) {
        return axios({
            url: "/user/sys/file/upload",
            method: "post",
            data
        })
    },
    //提交人才申请表 √
    submitForm(data) {
        return axios({
            url: "/user/declare/apply",
            method: "post",
            data
        })
    },
    //用户实名信息回显 √
    realNameEcho(params) {
        return axios({
            url: "/user/realName/echo",
            method: "get",
            params
        })
    },
    //用户申请记录回显 √
    declareEcho(params) {
        return axios({
            url: "/user/declare/echo",
            method: "get",
            params
        })
    },
    //用户申请记录 √
    declareRecord(params) {
        return axios({
            url: "/user/declare/record",
            method: "get",
            params
        })
    },
    //删除申请记录 √
    delRecord(data) {
        return axios({
            url: "/user/declare/delRecord",
            method: "post",
            data
        })
    },
    //查询用户是否具有年审资格 √
    checkApplyByYear(params) {
        return axios({
            url: "/user/declare/checkApplyByYear",
            method: "get",
            params
        })
    },
    //用户提交年审 √
    applyByYear(data) {
        return axios({
            url: "/user/declare/applyByYear",
            method: "post",
            data
        })
    },
    //进度查询 √
    getProgress(params) {
        return axios({
            url: "/user/declare/schedule",
            method: "get",
            params
        })
    },
    //申请变更信息 √
    applyChange(data) {
        return axios({
            url: "/user/change/submit",
            method: "post",
            data
        })
    },
    //《成都市急需紧缺人才和高端人才目录》 √
    getByTypeFile(params) {
        return axios({
            url: "/user/policy",
            method: "get",
            params
        })
    },
    //下载承诺书
    downloadPrimise(params,name) {
        return axios({
            url: "/user/sys/file/cns",
            method: "get",
            params,
            responseType: 'blob',
            headers: {
                'x-real-name': encodeURI(name),
              }
        })
    },
    //获取单位所在地
    getDistrict(params) {
        return axios({
            url: "/user/dict",
            method: "get",
            params,
        })
    },
}
export default declare