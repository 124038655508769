<template>
  <div class="home">
    <div>
      <van-nav-bar title="登录" />
      <div class="logo">
        <img class="logoimg"
             src="../assets/images/logo2.png"
             alt />
      </div>
      <div class="iptbox">
        <van-form validate-first
                  @failed="onFailed">
          <van-field left-icon="phone-circle-o"
                     style="margin-top:20px 0"
                     v-model="tel1"
                     type="tel"
                     name="tel"
                     placeholder="请输入手机号"
                     :rules="[{ pattern,  }]" />

          <div style="height:20px"></div>
          <van-field left-icon="bag-o"
                     v-model="password"
                     :type="passwordType"
                     placeholder="请输入密码"
                     :rules="[{ pattern2,}]">
            <template slot="right-icon">
              <span class="solts"
                    @click="switchPasswordType">
                <van-icon name="closed-eye"
                          v-if="passwordType==='password'" />
                <van-icon name="eye"
                          v-else />
              </span>
            </template>
          </van-field>
          <div class="dpflex">
            <div @click="regi('用户注册')">新用户注册</div>
            <div @click="regi('修改密码')">忘记密码</div>
          </div>
          <div>
            <van-checkbox style="margin-left:20px;margin-top:20px"
                          shape="square"
                          v-model="checked2"
                          checked-color="#60a3ff"
                          icon-size="14px">记住密码</van-checkbox>
          </div>
          <div class="check">
            <van-checkbox icon-size="14px"
                          v-model="checked">
              请阅读并勾选
              <span @click="toright"
                    style="color:#f60">《用户注册协议》</span>和
              <span @click="to"
                    style="color:#f60">《隐私协议》</span>
            </van-checkbox>
            <span class="ys">{{this.isshow}}</span>
          </div>

          <div class="btnbox">
            <button class="btn"
                    @click="loginbtn">登录</button>
          </div>
        </van-form>
      </div>
    </div>
    <div style="position: fixed;bottom: 15px; left: 33%;">
      <div style="color:#00f;margin:3px 0">技术支持：4000118778</div>
      <a href="https://beian.miit.gov.cn">蜀ICP备2021032893号-1</a>
    </div>

    <!-- <div class="wxtip" v-show="show">
      <div
        style="color:#fff;font-size:26px;display:flex;justify-content: space-between;margin-right:15px"
      >
        <div></div>
        <van-icon name="share-o" />
      </div>
      <div style="margin-top:200px">
        <div style="color:#fff;font-size:20px;">
          <van-icon name="warning-o" />点击右上角按钮
          <van-icon size="26px" name="more-o" />
        </div>
        <div style="color:#fff;font-size:20px;margin-top:20px">
          <van-icon name="warning-o" />选择在浏览器中打开
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import { login_api } from '../http/api/certification'
//import TopBar from '@/components/Nav.vue'

export default {
  name: 'Home',
  components: {
    //TopBar,
  },
  data () {
    return {
      isshow: '',
      checked: false,
      passwordType: 'password', //输入框类型
      tel1: '',
      password: '',
      show: true,
      checked2: false,
      pattern: /^1[3456789]\d{9}$/,
      pattern2: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{8,10}$/,
      appid: 'wxaba7baad272a7193',
      redirect_uri: 'https://mihuatang.xyz',
      code: null,
      mic: true
    }
  },
  created () {
    this.code = this.$route.query.code

    let self = this
    if (localStorage.getItem('autologin') == 'true') {
      var US_CODE = JSON.parse(localStorage.getItem('userphone'))
      var US_PWD = JSON.parse(localStorage.getItem('password'))
      self.tel1 = US_CODE
      self.password = US_PWD
      self.checked2 = true
      this.checked = true
    }
    const token = JSON.parse(localStorage.getItem('token'))
    if (token) {
      this.$router.push({
        path: '/index',
      })
    }
  },
  mounted () {
    this.is_weixin()

    if (!this.mic) {
      if (this.code == null) {
        location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx992b6dfbb02b02a1&redirect_uri=' + encodeURIComponent("https://mihuatang.xyz") + '&response_type=code&scope=snsapi_base#wechat_redirect'
      }
    }

  },
  methods: {
    is_weixin () {
      var ua = window.navigator.userAgent.toLowerCase()
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        //alert('微信浏览器')
        this.mic = false
      } else {
        //alert('不是微信浏览器')
        //this.show = false
      }
    },
    toright () {
      this.$router.push({
        path: '/rigister',
      })
    },
    //隐私
    to () {
      this.$router.push({
        path: '/Agreement',
      })
    },
    switchPasswordType () {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    //登录
    async loginbtn () {
      localStorage.setItem('code', this.code)
      //   alert(this.code);
      if (this.checked2) {
        window.localStorage.setItem('autologin', 'true')
        localStorage.setItem('userphone', JSON.stringify(this.tel1))
        localStorage.setItem('password', JSON.stringify(this.password))
      } else {
        window.localStorage.setItem('autologin', 'false')
      }
      if (this.checked != true) {
        this.isshow = '请勾选隐私协议'
      } else {
        // this.isshow=''
        let res = await login_api({
          phone: this.tel1, //手机号码
          password: this.password, //密码
        })
        console.log(res)

        sessionStorage.setItem('token', JSON.stringify(res.data))
        //localStorage.setItem('token', JSON.stringify(res.data))

        if (res.code == 200) {
          this.$router.push({
            path: '/index',
          })
        } else {
          //  this.$notify(res.message)
        }
      }
      console.log(11)
    },
    onFailed (errorInfo) {
      console.log('failed', errorInfo)
    },
    //去注册
    regi (e) {
      console.log(e)

      this.$router.push({ path: '/regis', query: { title: e } })
    },
  },
}
</script>
<style scoped lang="scss">
.wxtip {
  background: rgba(0, 0, 0, 0.8);
  text-align: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  //display: none;
}
.topbar {
  height: 40px;
}

#container {
  width: 100%;
  height: 100vh;
}

.van-cell::after {
  position: absolute;
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  right: 0.42667rem;
  bottom: 0;
  left: 0.42667rem;
  border-bottom: 0.05rem solid #4169e1;
  -webkit-transform: scaleY(0.5);
  transform: scaleY(0.5);
}

::v-deep .van-field__control {
  padding-left: 25px;
}

::v-deep .van-icon-phone-circle-o:before {
  content: "\e6fb";
  color: #4169e1;
  font-size: 25px;
}

::v-deep .van-icon-comment-circle-o:before {
  content: "\e695";
  color: #4169e1;
  font-size: 25px;
}

::v-deep .van-icon-bag-o:before {
  content: "\e66b";
  color: #4169e1;
  font-size: 25px;
}

.ys {
  color: red;
  margin: 10px 20px;
}

.logo {
  height: 200px;

  .logoimg {
    width: 100px;
    height: 100px;
    position: absolute;
    margin-left: 50%;
    margin-top: 10%;
    transform: translate(-50%);
  }
}

.check {
  margin: 5px 20px 0 20px;
  border: none;
  line-height: 10px;
}

.dpflex {
  margin-top: 10px;
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin-left: 5%;
  color: #666;
}

.btnbox {
  .btn {
    background-color: #4169e1;
    color: #fff;
    width: 90%;
    height: 40px;
    margin-left: 5%;
    border-radius: 3%;
    border: 0;
    font-size: 18px;
    margin-top: 20px;
    border-radius: 10px;
  }
}
</style>
